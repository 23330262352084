 import React, { Component } from 'react';
import './App.css';

class App extends Component {
	render() {
		return (
			<div className="App">
					
					<h1>Kleinsmith Memories</h1>

					<p><a href="https://youtu.be/if7-ov7VpD8" target="_blank" rel="noopener noreferrer">
					1989 Kleinsmith Wedding Highlights</a></p>

					<p><a href="https://youtu.be/nx-MU54JHXk" target="_blank" rel="noopener noreferrer">
					1989 Kleinsmith Wedding & Slideshow</a></p>

					<p><a href="https://youtu.be/PIjv7BHU9PA" target="_blank" rel="noopener noreferrer">
					1989 Kleinsmith Wedding Altar Camera</a></p>

					<p><a href="https://youtu.be/9k0G3W0nJ-k" target="_blank" rel="noopener noreferrer">
					1989 Kleinsmith Wedding Video</a></p>

					<p><a href="https://youtu.be/5gkAEZRU1bg" target="_blank" rel="noopener noreferrer">
					1989 Honeymoon</a></p>

					<p><a href="https://youtu.be/v2Q5-mQftTI" target="_blank" rel="noopener noreferrer">
					1993 Elijah's Baptism</a></p>

					<p><a href="https://youtu.be/4FpGiL984qk" target="_blank" rel="noopener noreferrer">
					1993 Elijah Baby Memories</a></p>

					<p><a href="https://youtu.be/lI6Xah-YuCU" target="_blank" rel="noopener noreferrer">
					1994-1995 Elijah Toddler Memories</a></p>

					<p><a href="https://youtu.be/I07Hz4aqJRw" target="_blank" rel="noopener noreferrer">
					1996-1998 Elijah Young Memories</a></p>

					<p><a href="https://youtu.be/JJzJB5q3xJk" target="_blank" rel="noopener noreferrer">
					1998-1999 Jacob Memories</a></p>

					<p><a href="https://youtu.be/0fGI_kDoQNE" target="_blank" rel="noopener noreferrer">
					2000 KY3 Kleinsmith New Nixa Superintendent</a></p>

					<p><a href="https://youtu.be/wE3sE5nZc-s" target="_blank" rel="noopener noreferrer">
					2003 Summer Memories</a></p>

					<p><a href="https://youtu.be/YwdU-jX_Wdk" target="_blank" rel="noopener noreferrer">
					2004 Jacob Basketball #1</a></p>

					<p><a href="https://youtu.be/0POyKIMJ9Dg" target="_blank" rel="noopener noreferrer">
					2004 Jacob Basketball #2</a></p>

					<p><a href="https://youtu.be/MQ5in0Fb-iA" target="_blank" rel="noopener noreferrer">
					2004 Jacob Basketball #3</a></p>

					<p><a href="https://youtu.be/6POSypilRWA" target="_blank" rel="noopener noreferrer">
					2004-2005 Golf & Other Memories</a></p>

					<p><a href="https://youtu.be/5lGKdPmJvCM" target="_blank" rel="noopener noreferrer">
					2005 Elijah 12 & Jacob 7</a></p>

					<p><a href="https://youtu.be/3YWpOauCSks" target="_blank" rel="noopener noreferrer">
					2006 Elijah Basketball & Jacob Mighty Mites</a></p>

					<p><a href="https://youtu.be/SsQRCsudxoA" target="_blank" rel="noopener noreferrer">
					2007 Boys Sports & Jacob Reading</a></p>

					<p><a href="https://youtu.be/fO3dgUiuWkI" target="_blank" rel="noopener noreferrer">
					2016 Xmas in NYC</a></p>

					<p><a href="https://youtu.be/YDnOxyyV-O0" target="_blank" rel="noopener noreferrer">
					2017 Dr. K Roast Event</a></p>

					<p><a href="https://youtu.be/IZxS4lxnYX8" target="_blank" rel="noopener noreferrer">
					Bobby Kleinsmith Family Slideshow</a></p>
					
			</div>
		);
	}
}

export default App;